import PropTypes from 'prop-types';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import isEmpty from 'lodash/fp/isEmpty';
import { breakpoints, colors, unitConverter as uc } from '../../../styles/base';
import { Heading2, Heading5 } from '../../atoms/Headings/Headings';

const segmentsContainerCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`;

const segmentCss = css`
  flex: 1 1 25%;

  @media (${breakpoints.mobile}) {
    flex: 1 1 50%;
    margin-bottom: ${uc('20px')};
  }
`;

const containerCss = css`
  margin: ${uc('70px')};
  background-color: ${colors.white};

  @media (${breakpoints.mobile}) {
    margin-bottom: ${uc('50px')};
  }

  @media (${breakpoints.largeDesktop}) {
    padding-right: ${uc('250px')};
    padding-left: ${uc('200px')};
  }
`;

const listCss = css`
  list-style: none;
`;

const ProductSegmentSection = ({
  partnerProductCategories,
  regions,
  title,
  types,
  verticals,
}) => {
  return (
    <div css={containerCss}>
      <Heading2>{title}</Heading2>
      <div css={segmentsContainerCss}>
        <ItemsList heading="TYPE:" items={types} />
        <ItemsList heading="CATEGORY:" items={partnerProductCategories} />
        <ItemsList heading="VERTICAL:" items={verticals} />
        <ItemsList heading="REGION:" items={regions} />
      </div>
    </div>
  );
};

const ItemsList = ({ heading, items }) => {
  if (isEmpty(items)) {
    return null;
  }
  return (
    <div css={segmentCss}>
      <Heading5>{heading}</Heading5>
      <ul>
        {items.map(i => (
          <li key={i.name} css={listCss}>
            {i.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProductSegmentSection.propTypes = {
  partnerProductCategories: PropTypes.arrayOf(PropTypes.object),
  regions: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.object),
  verticals: PropTypes.arrayOf(PropTypes.object),
};

ProductSegmentSection.defaultProps = {
  partnerProductCategories: null,
  regions: null,
  types: null,
  verticals: null,
};

ItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.string,
};

ItemsList.defaultProps = {
  items: null,
  heading: null,
};

export default ProductSegmentSection;
